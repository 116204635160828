.settings {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.settings > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}