
.channelHeader {
    margin-top: 0;
}

.channelEnergy .spacer {
    height: 2rem;
    /*margin-bottom: 2rem;*/
}

.channels {
    display: flex;
    align-items: center;
    justify-content: center;
}

.channel {
    position: relative;
    border-radius: 100%;
    overflow: hidden;
}

.channel {
    box-shadow: 0px 3px 6px 3px rgba(207,207,207,0.75);
    -webkit-box-shadow: 0px 3px 6px 3px rgba(183, 183, 183, 0.75);
    -moz-box-shadow: 0px 3px 6px 3px rgba(207,207,207,0.75);
}

.channel:after,
.channel .orb {
    position: absolute;
    top: -30%;
    left: -15%;
    right: -15%;
    bottom: 0;
    border-radius: 100%;
    background: radial-gradient(#ffffff, #ffd24b, #50400f);
    opacity: 1;
}

.channel:after {
    content: '';
    background: radial-gradient(#ffffff, #c0c0c0, #696969);
    opacity: 1;
    transition: opacity 200ms linear;
}

.channel:not(.spent):after {
    opacity: 0;
}

