
.plan {
    position: relative;

}

.plan .spacer {
    height: 50vh;
}

.plan .resetButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.planContent {
    max-height: 100vh;

    display: flex;
    flex-direction: column;
}

.planHeader {
    margin-top: 1.5rem;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.planHeader span {
    margin-right: 8px;
}

.planContent {

}

.planScroller {
    overflow: auto;
}

.planLevel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*.planLevel h3 {*/
/*    position: relative;*/
/*}*/

.fadeHeader > h3 {
    position: relative;
    margin: 0;
    z-index: 2;
}

.fadeHeader {
    position: sticky;
    top: 0;
    margin: 0;
    padding-bottom: 1rem;
    width: 500px;
    max-width: 100vw;
    z-index: 1;
}

.fadeHeader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(white, white, #00000000);
    z-index: 1;
}


.spellSearchButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: none;
    background-color: dodgerblue;

    height: 50px;
    width: 50px;
    padding: 4px 6px 6px 4px;
    border-radius: 10px;
}


.spellSlot {
    position: relative;
    background-color: rgba(102, 51, 153, 0.27);
    color: white;
    margin-bottom: 1rem;
    border-radius: 1rem;
    width: calc(500px - 2rem);
    max-width: calc(100vw - 3rem);
    overflow: hidden;
    padding: 1rem;
    text-align: center;
}
