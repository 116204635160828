
.spell {
    position: relative;
    color: white;
    margin-bottom: 10px;
    border-radius: 24px;
    width: 500px;
    max-width: calc(100vw - 1rem);
    overflow: hidden;
    transition: opacity 200ms linear;
}

.spellHeader {
    border-radius: 20px;
    margin: 5px;
}

.spell h3 {
    position: relative;
    padding: 10px;
}


.spell h3,
.spell .details {
    margin: 0;
}

.spell .details {
    border-radius: 10px;
    padding: 1px 1rem;
}

.spell .short_description {
    margin-top: 15px;
}

.spell .info {
    padding: 0 1rem 1rem 1rem;
}

.spell .attributes,
.spell .details {
    font-size: 14px;
}

.invalid.spell {
    border: 2px solid red;
    max-width: calc(100vw - (1rem + 4px));
    opacity: .5;
}

.spent.spell {
    opacity: .5;
}

.spellActionButton  {
    border: none;
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;
    font-size: 1.5rem;
}

.addSpellButton,
.spendSpellButton {
    position: absolute;
    top: 10px;
    right: 10px;
    padding-top: 2px;
}

.favSpellButton,
.removeSpellButton {
    position: absolute;
    top: 10px;
    left: 10px;
}

.favSpellButton {
    padding-bottom: 4px;
}


.spendSpellButton svg {
    margin-right: -2px;
    margin-bottom: -2px;
}

.favSpellButton svg {
    height: 30px;
    margin-left: -5px;
    margin-top: -1px;
}

.addSpellButton,
.removeSpellButton {
    padding: 0;
}

.addSpellButton svg,
.removeSpellButton svg {
    width: 32px;
    height: 32px;
}

.favSpellButton:not(.active) svg {
    opacity: .15;
}

.addBlinker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: white;
    opacity: 0;
    transition: opacity 400ms linear;
    pointer-events: none;
}

.addBlinker.show {
    transition: opacity 40ms linear;
    opacity: 80;
}

.addedCount {
    transform: rotate(180deg);

    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 50px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addedDot {
    /*flex: 1;*/
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: black;
    margin: 2px
}
