.App {
  /*overflow: auto;*/

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h1,
h2,
h3,
h4 {
  text-align: center;
}

.scroller {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.scroller::-webkit-scrollbar {
  display: none;
}

.appContent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.scrollyContent {
  height: 100%;
  display: flex;
}

.page {
  height: 100vh;

  display: inline-block;

  scroll-snap-align: start;
  scroll-snap-stop: always;

  max-height: 100vh;
  min-width: 100vw;

}

.highlight {
  box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 2px 2px rgb1(0,0,0,0.75);
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.move.loader #heart {
  animation: pulse 500ms infinite linear;
  transform-origin: 250px 250px;
}

.move.loader #flames {
  animation: wiggle 400ms infinite linear;
  transform-origin: 250px 300px;
}

@keyframes wiggle {
  0% {transform: rotate(0deg);}
  25% {transform: rotate(2deg);}
  50% {transform: rotate(0);}
  75% {transform: rotate(-2deg);}
}

@keyframes pulse {
  0% {transform: scale(1);}
  50% {transform: scale(.9);}

}