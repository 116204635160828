

.spellList {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
}

.spellList .spacer {
    height: 30vh;
}

.spellListContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
}

.spellList .spellsWrapper {
    overflow: auto;
}

.spellList .spells {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filters {
    padding-top: 1rem;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
}

.filterButtons {
    display: flex;
    justify-content: flex-start;
}

.filterButton {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    border: none;
    font-size: 1.2rem;
}

.filterButtons > * {
    margin-right: 8px;
}

.active.healing.filterButton {
    background-color: hotpink;
}

.active.fire.filterButton {
    background-color: #ff9100;
}

.active.favs.filterButton {
    background-color: #00ead7;
}

.placeholder {
    height: 50px;
    width: 50px;
}

.searchInput {
    position: relative;
    width: calc(100vw - 2rem);
    height: 2rem;
    margin: 1rem auto;
}

.searchInput input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: 1rem;
    outline: none;
}

.searchInput .resetSearchButton {
    position: absolute;
    top: 3px;
    right: 3px;
    height: 26px;
    width: 26px;
    border: none;
    background-color: #ddd;
    border-radius: 100%;
    padding: 0;
}

.searchInput .active.resetSearchButton {
    background-color: #444;
}

.searchInput .resetSearchButton svg {
    fill: white
}





